export default {
  'menu.welcome': 'Welcome',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Page',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register-result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.bookings': 'Bookings',
  'menu.bookings.calendars.list': 'Calendars',
  'menu.bookings.calendars.details': 'Calendar Details',
  'menu.bookings.calendars.create': 'Create Calendar',
  'menu.bookings.appointments.list': 'Appointments',
  'menu.bookings.customers.list': 'Customers',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.loyalty': 'Loyalty',
  'menu.loyalty.rewards': 'Rewards',
  'menu.loyalty.create-reward': 'Create Reward',
  'menu.loyalty.reward-details': 'Reward Details',
  'menu.loyalty.create-recurring-reward': 'Create Recurring Reward',
  'menu.loyalty.recurring-reward-details': 'Recurring Reward Details',
  'menu.loyalty.recurring-rewards': 'Recurring Rewards',
  'menu.loyalty.points': 'Points',
  'menu.loyalty.stamps': 'Stamps',
  'menu.loyalty.customers.list': 'Customers',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
  'menu.reports': 'Reports',
  'menu.reports.logs': 'Logs',
  'menu.reports.conversations': 'Conversations',
  'menu.reports.tags': 'Tags',
  'menu.reports.team-users': 'Team Users',
  'menu.reports.messages': 'Messages',
  'menu.inbox': 'Inbox',
  'menu.automations': 'Automations',
  'menu.automations.flows': 'Message Flows',
  'menu.automations.keywords': 'Keywords',
  'menu.automations.flow-details': 'Flow Details',
  'menu.automations.workflows': 'Workflows',
  'menu.automations.create-workflow': 'Create Workflow',
  'menu.automations.workflow-details': 'Workflow Details',
  'menu.automations.sequences': 'Sequences',
  'menu.automations.growth-tools': 'Growth Tools',
  'menu.automations.web-widget': 'Web Widget',
  'menu.contacts': 'Contacts',
  'menu.contacts.tag': 'Tags',
  'menu.contacts.list': 'All Contacts',
  'menu.contacts.custom-attribute': 'Custom Attributes',
  'menu.settings': 'Settings',
  'menu.broadcasts': 'Broadcasts',
  'menu.broadcasts.list': 'All Broadcasts',
  'menu.broadcasts.create': 'Create Broadcast',
  'menu.broadcasts.details': 'Broadcast Details',
  'menu.team-invitation': 'Team Invitation',
  'menu.plans': 'Plans',
  'menu.onboarding': 'Onboarding',
  'menu.flow-templates': 'Flow Templates',
  'menu.auto-login': 'Login',
  'menu.apps': 'Apps',
  'menu.apps.apps': 'Apps',
  'menu.apps.details': 'App Settings',
  'menu.message-templates': 'Message Templates',
  'menu.message-templates.message-templates': 'Message Templates',
  'menu.message-templates.create': 'Create Message Template',
  'menu.message-templates.details': 'Message Template Details',
  'menu.forms': 'Forms',
  'menu.forms.create': 'Create Form',
  'menu.forms.details': 'Form Details',
};
